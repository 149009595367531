import { PureComponent } from 'react';

export class PolygonIcon extends PureComponent {
  render() {
    return (
      <svg width="120px" height="100px" viewBox="0 0 120 100" {...this.props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-736.000000, -250.000000)" fillRule="nonzero">
            <g transform="translate(736.000000, 250.000000)">
              <g fill="#929DB3" fillOpacity="0.25">
                <g transform="translate(-0.000000, 0.000000)">
                  <path d="M29.8625678,89.5498051 L0,100 L0,10.6162466 L29.3272371,0.353386916 L29.390831,0 L29.8625678,0.166051714 L30.3370787,0 L30.3370787,0.333079859 L59.3686389,10.5522097 L88.7640449,0 L88.7640449,0.985510685 L88.9413928,0 L119.101124,10.6162466 L119.101124,100 L88.9413928,89.3837534 L88.7640449,88.3982375 L88.7640449,89.3837534 L59.5505618,99.8706573 L59.5505618,100 L59.3686389,99.935963 L59.1902504,100 L59.1746852,99.8676912 L29.8625678,89.5498051 Z" />
                </g>
              </g>
              <g transform="translate(30.000000, 24.000000)">
                <path
                  d="M5.51544425,7.74806947 C5.34947403,6.4203077 6.50890949,5.30723074 7.82879797,5.52721215 L52.828798,13.0272122 C53.893309,13.2046307 54.6250862,14.1945996 54.4824558,15.2643274 L50.7324558,43.3893274 C50.6252736,44.1931942 50.0432654,44.8527599 49.2589866,45.0591491 L13.6339866,54.4341491 C12.4639097,54.742064 11.2905156,53.9486402 11.1404442,52.7480695 L5.51544425,7.74806947 Z M9.81728975,9.91380247 L14.8260957,49.9842503 L46.9447025,41.5319854 L50.2616956,16.6545368 L9.81728975,9.91380247 Z"
                  fill="#B0B4B9"
                />
                <path
                  d="M60,15 C60,19.1423034 56.6423034,22.5 52.5,22.5 C48.3576966,22.5 45,19.1423034 45,15 C45,10.8576966 48.3576966,7.5 52.5,7.5 C56.6423034,7.5 60,10.8576966 60,15 Z"
                  fill="currentColor"
                  style={{ mixBlendMode: 'multiply' }}
                />
                <path
                  d="M15,7.5 C15,11.6423034 11.6423034,15 7.5,15 C3.35769656,15 0,11.6423034 0,7.5 C0,3.35769656 3.35769656,0 7.5,0 C11.6423034,0 15,3.35769656 15,7.5 Z"
                  fill="currentColor"
                  style={{ mixBlendMode: 'multiply' }}
                />
                <path
                  d="M20.625,52.5 C20.625,56.6423034 17.2673034,60 13.125,60 C8.98269656,60 5.625,56.6423034 5.625,52.5 C5.625,48.3576966 8.98269656,45 13.125,45 C17.2673034,45 20.625,48.3576966 20.625,52.5 Z"
                  fill="currentColor"
                  style={{ mixBlendMode: 'multiply' }}
                />
                <path
                  d="M56.25,43.125 C56.25,47.2673034 52.8923034,50.625 48.75,50.625 C44.6076966,50.625 41.25,47.2673034 41.25,43.125 C41.25,38.9826966 44.6076966,35.625 48.75,35.625 C52.8923034,35.625 56.25,38.9826966 56.25,43.125 Z"
                  fill="currentColor"
                  style={{ mixBlendMode: 'multiply' }}
                />
                <polygon fill="#FFFFFF" points="5.625 5.625 9.375 5.625 9.375 9.375 5.625 9.375" />
                <polygon
                  fill="#FFFFFF"
                  points="50.625 13.125 54.375 13.125 54.375 16.875 50.625 16.875"
                />
                <polygon fill="#FFFFFF" points="46.875 41.25 50.625 41.25 50.625 45 46.875 45" />
                <polygon fill="#FFFFFF" points="11.25 50.625 15 50.625 15 54.375 11.25 54.375" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
