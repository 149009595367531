import { PureComponent } from 'react';

export class MappedIcon extends PureComponent {
  render() {
    return (
      <svg viewBox="0 0 60 60" {...this.props}>
        <g fillRule="evenodd" fill="none" strokeWidth="1" stroke="none">
          <g fillRule="nonzero" transform="translate(-766,-274)">
            <g transform="translate(736,250)">
              <g fillOpacity="0.25" fill="#929db3">
                <g />
              </g>
              <g transform="translate(30,24)">
                <path
                  fill="#b0b4b9"
                  d="M 5.5154442,7.7480695 C 5.349474,6.4203077 6.5089095,5.3072307 7.828798,5.5272122 l 45,7.4999998 c 1.064511,0.177419 1.796288,1.167388 1.653658,2.237115 l -3.75,28.125 c -0.107182,0.803867 -0.689191,1.463433 -1.473469,1.669822 l -35.625,9.375 C 12.46391,54.742064 11.290516,53.94864 11.140444,52.748069 Z M 9.8172898,9.9138025 14.826096,49.98425 46.944702,41.531985 50.261696,16.654537 Z"
                />
                <path
                  style={{ mixBlendMode: 'multiply' }}
                  fill="currentColor"
                  d="m 60,15 c 0,4.142303 -3.357697,7.5 -7.5,7.5 -4.142303,0 -7.5,-3.357697 -7.5,-7.5 0,-4.142303 3.357697,-7.5 7.5,-7.5 4.142303,0 7.5,3.357697 7.5,7.5 z"
                />
                <path
                  style={{ mixBlendMode: 'multiply' }}
                  fill="currentColor"
                  d="M 15,7.5 C 15,11.642303 11.642303,15 7.5,15 3.3576966,15 0,11.642303 0,7.5 0,3.3576966 3.3576966,0 7.5,0 11.642303,0 15,3.3576966 15,7.5 Z"
                />
                <path
                  style={{ mixBlendMode: 'multiply' }}
                  fill="currentColor"
                  d="m 20.625,52.5 c 0,4.142303 -3.357697,7.5 -7.5,7.5 -4.1423034,0 -7.5,-3.357697 -7.5,-7.5 0,-4.142303 3.3576966,-7.5 7.5,-7.5 4.142303,0 7.5,3.357697 7.5,7.5 z"
                />
                <path
                  style={{ mixBlendMode: 'multiply' }}
                  fill="currentColor"
                  d="m 56.25,43.125 c 0,4.142303 -3.357697,7.5 -7.5,7.5 -4.142303,0 -7.5,-3.357697 -7.5,-7.5 0,-4.142303 3.357697,-7.5 7.5,-7.5 4.142303,0 7.5,3.357697 7.5,7.5 z"
                />
                <polygon points="5.625,5.625 9.375,5.625 9.375,9.375 5.625,9.375 " fill="#ffffff" />
                <polygon
                  points="50.625,13.125 54.375,13.125 54.375,16.875 50.625,16.875 "
                  fill="#ffffff"
                />
                <polygon points="46.875,41.25 50.625,41.25 50.625,45 46.875,45 " fill="#ffffff" />
                <polygon points="11.25,50.625 15,50.625 15,54.375 11.25,54.375 " fill="#ffffff" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
