import { PureComponent } from 'react';

export class WavesIcon extends PureComponent {
  render() {
    return (
      <svg width="25" height="18" viewBox="0 0 25 18" {...this.props}>
        <g fill="none" fillRule="evenodd">
          <path d="M-7-11h39.89v40H-7z" />
          <path
            d="M.81 9.516c1.434 1.233 3.059 2.63 6.202 2.63 3.144 0 4.769-1.397 6.203-2.63 1.356-1.167 2.527-2.174 4.97-2.174 2.442 0 3.614 1.007 4.97 2.174a.945.945 0 0 0 1.335-.103.951.951 0 0 0-.103-1.339c-1.433-1.233-3.059-2.63-6.202-2.63-3.144 0-4.77 1.397-6.203 2.63-1.356 1.167-2.527 2.174-4.97 2.174-2.442 0-3.613-1.007-4.97-2.174a.945.945 0 0 0-1.334.103.951.951 0 0 0 .102 1.339z"
            fill="currentColor"
            fillRule="nonzero"
          />
          <path
            d="M.81 4.322c1.434 1.234 3.059 2.632 6.202 2.632 3.144 0 4.769-1.398 6.203-2.632 1.356-1.166 2.527-2.173 4.97-2.173 2.442 0 3.613 1.007 4.97 2.173a.945.945 0 0 0 1.335-.102.951.951 0 0 0-.103-1.339C22.953 1.648 21.328.25 18.185.25c-3.144 0-4.77 1.398-6.203 2.631-1.356 1.166-2.527 2.174-4.97 2.174-2.442 0-3.613-1.007-4.97-2.174a.945.945 0 0 0-1.334.103.951.951 0 0 0 .102 1.338zM24.387 13.267c-1.433-1.233-3.059-2.63-6.202-2.63-3.144 0-4.77 1.397-6.203 2.63-1.356 1.167-2.527 2.174-4.97 2.174-2.442 0-3.613-1.007-4.97-2.174a.945.945 0 0 0-1.334.103.951.951 0 0 0 .102 1.339c1.434 1.233 3.059 2.63 6.202 2.63 3.144 0 4.769-1.397 6.203-2.63 1.356-1.167 2.527-2.174 4.97-2.174 2.442 0 3.614 1.007 4.97 2.174a.945.945 0 0 0 1.335-.103.951.951 0 0 0-.103-1.339z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
}
