import { PureComponent } from 'react';

export class ValidationIcon extends PureComponent {
  render() {
    return (
      <svg width="136" height="100" viewBox="0 0 136 100" {...this.props}>
        <g fillRule="nonzero" fill="none">
          <path
            d="M33.837 76.033L6.604 84.906V9.014L33.349.3l.058-.3.43.141L34.27 0v.283l26.475 8.676L87.552 0v.837L87.714 0l27.504 9.014v75.892l-27.504-9.014-.162-.837v.837l-26.641 8.904v.11l-.166-.055-.163.055-.014-.113-26.731-8.76z"
            fillOpacity=".25"
            fill="#929DB3"
          />
          <path
            d="M106.68 78.89c-5.685-3.288-9.51-9.435-9.51-16.475 0-10.504 8.515-19.019 19.019-19.019 10.504 0 19.02 8.515 19.02 19.02 0 7.04-3.826 13.186-9.51 16.474v18.782a1.585 1.585 0 0 1-2.393 1.364l-6.309-3.739a1.585 1.585 0 0 0-1.616 0l-6.309 3.739a1.585 1.585 0 0 1-2.393-1.363V78.89zm9.509-3.795c7.003 0 12.68-5.677 12.68-12.68 0-7.002-5.677-12.679-12.68-12.679s-12.68 5.677-12.68 12.68c0 7.002 5.677 12.679 12.68 12.679zm9.51-12.68a9.51 9.51 0 1 1-19.02 0 9.51 9.51 0 0 1 19.02 0z"
            fill="currentColor"
            style={{ mixBlendMode: 'multiply' }}
          />
          <path
            d="M57.318 42.795c-5.883 0-10.651 4.769-10.651 10.651 0 9.13 10.65 16.738 10.65 16.738S67.97 62.576 67.97 53.446c0-5.882-4.769-10.65-10.651-10.65zm0 16.738a6.086 6.086 0 1 1 0-12.173 6.086 6.086 0 0 1 0 12.173zM90.337 8.833c-5.883 0-10.652 4.769-10.652 10.651 0 9.13 10.652 16.738 10.652 16.738s10.651-7.608 10.651-16.738c0-5.882-4.769-10.651-10.651-10.651zm0 16.738a6.086 6.086 0 1 1 0-12.173 6.086 6.086 0 0 1 0 12.173z"
            fill="#929DB3"
            opacity=".25"
          />
          <path
            d="M13.71 16.557C6.418 16.557.504 22.634.504 30.13c0 11.635 13.208 21.331 13.208 21.331s13.207-9.696 13.207-21.33c0-7.498-5.913-13.575-13.207-13.575zm0 21.33c-4.167 0-7.546-3.472-7.546-7.756 0-4.284 3.379-7.757 7.547-7.757 4.168 0 7.547 3.473 7.547 7.757s-3.38 7.757-7.547 7.757z"
            fill="currentColor"
            style={{ mixBlendMode: 'multiply' }}
          />
        </g>
      </svg>
    );
  }
}
